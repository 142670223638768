import config from 'config'
import sniffer from 'sniffer'
import classes from 'dom-classes'
import create from 'dom-create-element'
import gsap from 'gsap'

TweenLite.defaultEase = Expo.easeOut

class Preloader {
	
	constructor(onComplete) {
		
		this.preloaded = onComplete
		this.view = config.view
		this.el = null
	}
	
	init(req, done) {

		classes.add(config.body, 'is-loading')

		config.infos = sniffer.getInfos()
        
		this.createDOM()

		done()
	}
	
	createDOM() {
		
		const page = this.view.firstChild
		
		this.el = create({
			selector: 'div',
			styles: 'preloader',
			html: `
				<div class="vertical-align">
					<div class="vertical-align__item">
						<span class="percentage">%</span>
						<span class="count">0</span>
					</div>
				</div>
			`
		})

		this.view.insertBefore(this.el, page)
	}

	resize(width, height) {

		config.width = width
		config.height = height
	}

	animateIn(req, done) {

		

		const percentage = document.querySelector('.percentage')
		const count = document.querySelector('.count')
		let countUp = {count:0}
		let _this = this

		TweenLite.to(this.el, 1, {autoAlpha: 1})

		TweenLite.from(['.percentage', '.count'], 1, {autoAlpha:0, delay:.5})

		function loadanim() {
			TweenLite.to(countUp, 2.5, {count:"+=100", roundProps:"count", delay:1, onUpdate:updateHandler, ease: SlowMo.ease.config(0.1, 0.7, false), onComplete:function(){

				done()
				// call this.preloaded to bring the first route
				_this.preloaded()

			}});
		}

		function updateHandler() {
		  
		  count.innerHTML = countUp.count
		}

		
		loadanim()
		


	}
	
	animateOut(req, done) {

		const tl = new TimelineMax({ paused: true, onComplete: done })
		tl.to(this.el, 0.7, { autoAlpha:0})
		  .to('.bar.top', 0.7, { y: '-20px' }, '-0.5')
		  .to('.bar.left', 0.7, { x: '-20px' }, '-0.5')
		  .to('.bar.right', 0.7, { x: '20px' }, '-0.5')
		  .to('.bar.bottom', 0.7, { y: '20px' }, '-0.5')

		tl.restart()
	}

	destroy(req, done) {

		var _this = this
		classes.add(config.body, 'is-loaded')
		classes.remove(config.body, 'is-loading')

		_this.view.removeChild(_this.el)
			done()
		

		
	}
}

module.exports = Preloader