import framework from 'framework'
import config from 'config'
import utils from 'utils'
import domselect from 'dom-select'
import event from 'dom-event'
import classes from 'dom-classes'
import query from 'query-dom-components'
import biggie from '@utils/biggie'
import _ from 'underscore'

class Default {
    
    constructor(opt = {}) {
          
        this.view = config.view
        this.page = null
        this.a = null

        this.debouncedResize = _.debounce(this.debounce.bind(this), 500)
    }
    
    init(req, done, options) {

       const opts = options || { cache: true, sub: false }
        const view = this.view
        let page

        if (req.previous === undefined) {
            page = this.view.querySelector('.page');
            classes.remove(page, 'is-hidden')
            this.ready(done)
          } else {
            const ready = this.ready.bind(this, done)
            page = biggie.page(req, view, opts, ready)
          }

          this.page = page;
    }


    initColors(){

        const main = document.querySelector('.page-wrapper').dataset.main
        const secondary = document.querySelector('.page-wrapper').dataset.secondary
        const complementary = document.querySelector('.page-wrapper').dataset.complementary
        const color = document.querySelector('.page-wrapper').dataset.color

        TweenMax.set('.bar', { background: main })
        TweenMax.set('.logo-icon #outer', { fill: main })
        TweenMax.set('.logo-icon #inner', { fill: secondary })
        TweenMax.set('.hamburger .dot', { background: main })
        TweenLite.set('.menubg.left', { background: secondary })
        TweenLite.set('.menubg.right', { background: complementary })

        TweenLite.set('.menu a', { color: color })
        TweenLite.set('.menu .subtitle', { color: color })
        TweenLite.set('.menu .line', { background: secondary })

    }

    ready() {

        this.ui = query({ el: this.page })
        
        this.a = domselect.all('a', this.page)
        
        biggie.bind.add(this.a)
    }
    
    resize(width, height) {
        
        config.height = height
        config.width = width

        config.isMobile = config.width <= 768
        config.isTablet = config.width <= 1024

        this.debouncedResize()

    }

    debounce() {}
    
    destroy() {

        biggie.bind.remove(this.a)

        this.a = null
    }
}

module.exports = Default