import array from './array'
import biggie from './biggie'
import css from './css'
import dom from './dom'
import func from './func'
import math from './math'

export default {
    array: array,
    biggie: biggie,
    css: css,
    dom: dom,
    func: func,
    math: math
}