import min from './min'
import max from './max'
import slice from './slice'
import combine from './combine'
import without from './without'

export default {
    min: min,
    max: max,
    slice: slice,
    combine: combine,
    without: without
}