import domselect from 'dom-select'

const config = {
	
	BASE: '/',
	
	html: domselect('html'),
	body: document.body,
	view: domselect('main'),

	header: domselect('header'),
	logo: domselect('.logo'),
	menu: domselect('.menu'),
    
	width: window.innerWidth,
	height: window.innerHeight,

	bars: {
		left: domselect('.bar.left'),
		top: domselect('.bar.top'),
		right: domselect('.bar.right'),
		bottom: domselect('.bar.bottom')
	},

	that: this,

	isMobile: window.innerWidth <= 768,
	isTablet: window.innerWidth <= 1024
}

export default config