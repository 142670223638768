import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'
import Split from '../lib/splittext'
import ajax from 'please-ajax'
import bodymovin from '../lib/bodymovin'

class Terms extends Default {
	
	constructor(opt) {
		
		super(opt)

		this.slug = 'home'
		this.ui = null
		this.color = '#101C27'
	}
	
	init(req, done) {

		super.init(req, done)
	}

	ready(done) {

		super.ready()


		config.html.style.background = this.color

		
		this.addEvents()

		done()
	}

	addEvents(){

		//this.animateSVG()

		//this.addAnimations()

	}

		


	animateIn(req, done) {

		classes.add(config.body, `is-${this.slug}`)
		classes.remove(config.header, 'hidden')

		this.initColors()
		const _that = this

		const tl =  new TimelineMax({ paused: true, onComplete: () => {
			
			done()
		}})

		const mySplitText = new Split('h1', {type:"words,chars"})
    	const chars = mySplitText.chars;
    	const _this = this
		
		tl

		.to(this.page, 0.5, {
			autoAlpha: 1,
			ease: Expo.easeInOut,
		})

		.to('.bar.top', 0.7, { y: '0px' }, '0.5')
		.to('.bar.left', 0.7, { x: '0px' }, '0.5')
		.to('.bar.right', 0.7, { x: '0px' }, '0.5')
		.to('.bar.bottom', 0.7, { y: '0px' }, '0.5')


		.fromTo('.logo', .7, {
			y:'-100px',
			autoAlpha: 0,	
		}, {

			y:'0px',
			autoAlpha:1
		})

		.staggerFrom(chars, .7, {
			y:'20px',
			autoAlpha: 0,
			ease: Expo.easeInOut,
			onComplete:function() {
				
			}
		}, 0.01, "-=.5")

		.from('p', .7, {
			y:'20px',
			autoAlpha: 0,
			ease: Expo.easeInOut,
		},'-=.4')

		

		tl.restart()
	}

	animateOut(req, done) {
		
		classes.remove(config.body, `is-${this.slug}`)


		const tl =  new TimelineMax({ paused: true, onComplete: () => {
			
			done()
		}})

		.to(this.page, 0.5, {
			autoAlpha: 0,
			ease: Expo.easeInOut,
			
		})

		.to('.logo', .7, {
			y:'-100px',
			autoAlpha: 0,
			ease: Expo.easeInOut,
		},'-0.8')

		.to('.bar.top', 0.7, { y: '-20px' }, '-0.5')
	  	.to('.bar.left', 0.7, { x: '-20px' }, '-0.5')
	    .to('.bar.right', 0.7, { x: '20px' }, '-0.5')
	    .to('.bar.bottom', 0.7, { y: '20px' }, '-0.5')


	    tl.restart()

	}

	resize(width, height) {

		super.resize(width, height)
		
	}

	destroy(req, done) {

		super.destroy()

		this.ui = null

		this.page.parentNode.removeChild(this.page)
		
		done()
	}
}

module.exports = Terms