import framework from 'framework'
import domselect from 'dom-select'
import biggie from '@utils/biggie'
import config from 'config'
import array from '@utils/array'
import classes from 'dom-classes'

class App {
    
  constructor(opt = {}) {
    
    //console.log(`%c${'Developed by @mariosmaselli'} – ${'https://twitter.com/mariosmaselli'}`, 'color: #000; font-size:14px')
    
    this.init()
 
    const _that = this

    this.handler.bind(_that)
  }
    
  init() {
    
    this.addEvents()

    //console.log(this)

    framework.init()
  }

  addEvents() {


    this.burger = document.querySelector('.hamburger')
    this.nav = array.slice(config.menu.querySelectorAll('a'))


    config.logo.addEventListener('click', this.handler)
    this.burger.addEventListener('click', this.menuBurger)
    this.nav.forEach((el) => el.addEventListener('click', this.handler))

    

    
    // biggie.bind.add(domselect.all('nav a'))
  }


 handler(e) {

      e.preventDefault()

      const target = this;
      const href = target.getAttribute('href')
      const tl =  new TimelineMax({ paused: true})

      target.hasAttribute('href') && framework.go(href)

      tl.to('.menu', 0.5, {autoAlpha:0, onComplete:function(){

          classes.add(config.menu, 'hidden')

      }})

        tl.restart()


  }

  menuBurger() {

    const tl =  new TimelineMax({ paused: true})

    //console.log('menu called')

    if( config.menu.className.match(/hidden/g) ) {

      classes.remove(config.menu, 'hidden')
      TweenLite.set('.menu', {autoAlpha:1})


       tl.staggerFrom('.menubg', 0.5, {width:0}, 0.1)
        .from('.subtitle', 0.5, {autoAlpha:0})
        .from('.mail', 0.5, {autoAlpha:0, y:10, ease: Back.easeOut.config(1)})
        .staggerFrom('.main-nav a', 0.5, {autoAlpha:0, y: 10, ease: Back.easeOut.config(1)}, 0.1,'-=.5')
        


    }else {

      tl.to('.menu', 0.5, {autoAlpha:0, onComplete:function(){

          classes.add(config.menu, 'hidden')

      }})
        

    }

    tl.restart()

    
  }

}

module.exports = App