import bind from './bind'
import route from './route'
import slug from './slug'
import page from './page'

export default {
    bind: bind,
    route: route,
    slug: slug,
    page: page
}